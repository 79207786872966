<script>
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
import { networkRequesListResponseLogic } from "@/common/http-common-response-list"
import { ApiClientSelectedPlatform } from "@/common/http-common"
export default {
  emits: ['selectItem'],
  data() {
    return {
      title: this.$t('employes.employes'),
      items: [],
      pagination: {
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }
    };
  },
  props: {
    fieldLabel: {
      type: String,
      default: '',
    },
    emptyLabel: {
      type: String,
      default: '',
    },
    item: {
      default: null
    },
    discardItem:{
      default: null
    }
  },
  methods: {
    generateFieldLabel() {

    },
    selectDataItem(item) {
      this.$emit('selectItem', item)
      this.$refs['modal-standard'].hide()
    },
    removeDataItem() {
      this.$emit('selectItem', null)
    },
    hideModal() {
      this.$refs['modal-standard'].hide()
    },
    showModalModal() {
      this.getData(1, this.pagination.pageSize)
      this.$refs['modal-standard'].show()
    },
    getData: function (page, size) {
      const self = this;
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";
      var rs = JSON.parse(atob(this.$route.query.data))
      ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/employees/list?page=" + page + "&size=" + size).then(function (response) {
        
        networkRequesListResponseLogic(self, response, page, size, "")
      }).catch(
        function (error) {
          networkRequesErrorResponseLogic(self, error)
        }
      )
    },
  }
}
</script>
<template>
  <div class="row">
    <div class="col-lg-8">
      <b-form-group id="platform" label-cols-lg="4" :label="fieldLabel" label-for="platform" class="mb-0 mt-0">
        <p v-if="item" name="labelProvder" class="mb-0 mt-0" style="position: absolute;
  top: 50%;
  transform: translateY(-50%);"> 
  <b v-if="item.user">{{ item.user.first_name }} {{ item.user.last_name }}</b>
  <b v-else></b>
  {{ item.email }}<br>
          {{ item.detail.permission.title }}</p>
        <p v-else name="labelProvder" class="mb-0 mt-0" style="position: absolute;
  top: 50%;
  transform: translateY(-50%);">{{ emptyLabel }}</p>
      </b-form-group>
    </div>
    <div class="col-lg-4">
      <b-button-group class="mx-1" style="float:right!important">
        <b-button v-if="item != null" variant="danger" @click="removeDataItem">{{ $t("constants.REMOVE")}}</b-button>
        <b-button variant="primary" @click="showModalModal">{{ $t("constants.SELECT")}}</b-button>
      </b-button-group>
      <b-modal id="modal-standard" ref="modal-standard" :title="fieldLabel" title-class="font-18" size="lg"
        hide-footer scrollable no-close-on-backdrop>

        <table class="table mb-0 mt-0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("form.agent.labels.agent") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.id }}</td>
              <td v-if="item.user">
                {{ item.user.first_name }} {{ item.user.last_name }}
                {{ $t("form.agent.labels.email") }}<div>{{ item.email }} </div>
                {{ $t("form.agent.labels.status") }} <div>{{ $t("constants." + item.detail.status) }}</div>
              </td>
              <td v-else>
                {{ $t("form.agent.labels.email") }}<div>{{ item.email }} </div>
                {{ $t("form.agent.labels.status") }} <div>{{ $t("constants." + item.detail.status) }}</div>
              </td>
              <td>
                {{ $t("form.agent.labels.position") }} <div>{{ item.detail.permission.title }}</div>
                {{ $t("form.agent.labels.platform") }} <div>{{ item.detail.domain.title }}</div>
              </td>
              <td  v-if="item.id != discardItem">
                <a  href="#" @click="selectDataItem(item)">{{ $t("constants.SELECT") }} </a>
              </td>
              <td  v-else>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row" v-if="pagination.totalPages != 1 && items.length != 0">
          <div class="col-sm-6">
            <div>
              <p class="mb-sm-0 mt-2">
                {{ this.$t("pagination.page") }}
                <span class="font-weight-bold">{{ pagination.page }}</span> {{ this.$t("pagination.from") }}
                <span class="font-weight-bold">{{ pagination.totalPages }}</span>
              </p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="float-sm-right">
              <ul class="pagination pagination-rounded mb-sm-0">
                <li class="page-item" v-bind:class="(pagination.page == 1) ? 'disabled' : ''">
                  <a href="#" class="page-link" @click="getData(parseInt(pagination.page) - 1, pagination.pageSize)">
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li class="page-item" v-for="item in pagination.backPages" :key="item">
                  <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                </li>
                <li class="page-item active">
                  <a href="#" class="page-link">{{ pagination.page }}</a>
                </li>
                <li class="page-item" v-for="item in pagination.nextPages" :key="item">
                  <a href="#" class="page-link" @click="getData(item, pagination.pageSize)">{{ item }}</a>
                </li>
                <li class="page-item" v-bind:class="(pagination.page == pagination.totalPages) ? 'disabled' : ''">
                  <a href="#" class="page-link" @click="getData(parseIn(pagination.page) + 1, pagination.pageSize)">
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" block @click="hideModal">{{ $t("constants.CANCEL") }}</button>
        </div>
      </b-modal>
      <div>
      </div>
    </div>
  </div>

</template>
<style>
.modal-body {
  padding-top: 0px !important
}
</style>