<template>
    <div class="col-12" v-if="permissionsList != null">
        <form class="form-horizontal" role="form" @submit.prevent="submitForm">
            <div class="row">
                <div class="col-6" v-if="this.type == 'UPDATE' || this.statusSelectedField == 'INVITED'">
                    <b-form-group id="form.agent.labels.firstName" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.agent.labels.firstName')" label-for="form.agent.labels.firstName">
                        <b-form-input id="form.agent.labels.firstName" v-model="firstNameField" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6" v-if="this.type == 'UPDATE' || this.statusSelectedField == 'INVITED'">
                    <b-form-group id="form.agent.labels.lastName" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.agent.labels.lastName')">
                        <b-form-input id="form.agent.labels.lastName" v-model="lastNameField" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group id="form.agent.labels.email" label-cols-sm="6" label-cols-lg="6"
                        :label="$t('form.agent.labels.email')">
                        <b-form-input id="form.agent.labels.email" v-model="emailField" @keydown="validateForm()"
                            :disabled="this.type == 'UPDATE'"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6" v-if="this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_PERMISSIONS')">
                    <ModalSelectPermission :fieldLabel="$t('form.agent.labels.position')"
                        :emptyLabel="$t('form.agent.labels.positionEmpty')" :item="permissionField"
                        @selectItem="selectPermission"></ModalSelectPermission>
                </div>
                <div class="col-12">
                    <br>
                </div>
                <div class="col-6" v-if="this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_LEADER')">
                    <ModalSelectAgent :fieldLabel="$t('form.agent.labels.leader')"
                        :emptyLabel="$t('form.agent.labels.leaderEmpty')" :item="leaderSelectField"
                        @selectItem="selectLeader" :discardItem="this.agentId"></ModalSelectAgent>
                    <br>
                    <b-form-group v-if="leaderSelectField != null" id="form.agent.labels.leaderPercentage"
                        label-cols-sm="6" label-cols-lg="6" :label="$t('form.agent.labels.leaderPercentage')">
                        <b-form-input id="form.agent.labels.leaderPercentage" type="number"
                            v-model="leaderEarningsField"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6" v-if="this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_OWNER')">
                    <ModalSelectAgent :fieldLabel="$t('form.agent.labels.owner')"
                        :emptyLabel="$t('form.agent.labels.ownerEmpty')" :item="ownerSelectField"
                        @selectItem="selectOwner" :discardItem="this.agentId"></ModalSelectAgent>
                    <br>
                    <b-form-group v-if="ownerSelectField != null" id="form.agent.labels.ownerPercentage"
                        label-cols-sm="6" label-cols-lg="6" :label="$t('form.agent.labels.ownerPercentage')">
                        <b-form-input id="form.agent.labels.ownerPercentage" type="number"
                            v-model="ownerEarningsField"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12">
                    <br>
                </div>
                <div class="col-6" v-if="this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_EARNINGS')">
                    <ModalSelectInvoiceType :fieldLabel="$t('form.agent.labels.createOrder')"
                        @selectItem="selectOrderType" @removeItem="removeOrderType" :discardItems="selectedOrderTypes">
                    </ModalSelectInvoiceType>
                    <br>
                    <b-form-group v-for="(item, index) in selectedOrderTypes" :key="index"
                        id="form.agent.labels.ownerPercentage" label-cols-sm="6" label-cols-lg="6"
                        :label="item.title + $t('form.agent.labels.createOrderWithPercentage')">
                        <b-form-input id="form.agent.labels.ownerPercentage" type="number"
                            v-model="item.value"></b-form-input>

                    </b-form-group>

                </div>
            </div>
            <b-button
                v-if="(this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_EDIT') && this.type == 'UPDATE') || (this.checkPermission(this.permissionsList, 'MODULE_EMPLOYEES_ADD') && this.type == 'CREATE')"
                variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save')
                }}</b-button>
        </form>
    </div>
</template>

<script>
import ModalSelectAgent from "@/views/pages/agent/modal-select.vue"
import ModalSelectInvoiceType from "@/views/pages/invoice-type/modal-select.vue"
import ModalSelectPermission from "@/views/pages/permission/modal-select.vue"
import { ApiClientSelectedPlatform } from "@/common/http-common"
import { networkRequesErrorResponseLogic } from "@/common/http-common-response-error"
export default {
    components: {
        ModalSelectAgent, ModalSelectPermission, ModalSelectInvoiceType
    },
    data() {
        return {
            formValidationButton: false,
            firstNameField: "",
            lastNameField: "",
            emailField: "",
            leaderSelectField: null,
            leaderEarningsField: null,
            ownerSelectField: null,
            ownerEarningsField: null,
            statusSelectedField: null,
            permissionField: null,
            agentId: null,
            selectedOrderTypes: [],
            permissionsList:null
        };
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: ''
        }
    },
    watch: {
        'item'() {
            if (this.item) {
                this.agentId = this.item.id
            }
            if (this.item) {
                if (this.item.user) {
                    if (this.item.user.first_name) {
                        this.firstNameField = this.item.user.first_name
                    }
                }
            }
            if (this.item) {
                if (this.item.user) {
                    if (this.item.user.last_name) {
                        this.lastNameField = this.item.user.last_name
                    }
                }
            }
            if (this.item) {
                if (this.item.email) {
                    this.emailField = this.item.email
                }
            }

            if (this.item) {
                if (this.item.detail) {
                    if (this.item.detail.leader) {
                        this.leaderSelectField = this.item.detail.leader
                    }
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    if (this.item.detail.owner) {
                        this.ownerSelectField = this.item.detail.owner
                    }
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    if (this.item.detail.owner) {
                        this.statusSelectedField = this.item.detail.status
                    }
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    this.leaderEarningsField = this.item.detail.leaderEarnings
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    this.ownerEarningsField = this.item.detail.ownerEarnings
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    if (this.item.detail.permission) {
                        this.permissionField = this.item.detail.permission
                    }
                }
            }
            if (this.item) {
                if (this.item.detail) {
                    if (this.item.detail.order_types) {
                        this.selectedOrderTypes = this.item.detail.order_types
                    }
                }
            }
            this.validateForm();
        },
    },
    computed: {
        isFormValid() {
            return true
        },
    },
    methods: {
        checkPermission: function (response, p) {
            var returnValue = false
            response.data.item.permissions.forEach(function (permission) {
                                if (permission.permission.type == p) {
                                    returnValue = true
                                }
                            })
            return returnValue
        },
        getPermissions: function () {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";
            var rs = JSON.parse(atob(this.$route.query.data))
            ApiClientSelectedPlatform(rs.platformId).get("/v1/admin/user/permission").then((response) => {
                this.permissionsList = response
                this.$forceUpdate();
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }).catch(
                function (error) {
                    networkRequesErrorResponseLogic(self, error)
                }
            )
        },
        selectOrderType(item) {
            item.value = 0
            this.selectedOrderTypes.push(item)
        },
        removeOrderType(item) {
            for (let i = 0; i < this.selectedOrderTypes.length; i++) {
                if (item.id == this.selectedOrderTypes[i].id) {
                    this.selectedOrderTypes.splice(i, 1);
                    break
                }
            }
        },
        selectPermission(item) {
            this.permissionField = item
            this.validateForm()
        },
        selectLeader(item) {
            this.leaderSelectField = item
            this.validateForm()
        },
        selectOwner(item) {
            this.ownerSelectField = item
            this.validateForm()
        },
        validateForm() {
            if (this.type == "CREATE") {
                var disabled = false
                if (this.permissionField == null) {
                    disabled = true
                }
                var re = /\S+@\S+\.\S+/;

                if (!re.test(this.emailField)) {
                    disabled = true
                }


                this.formValidationButton = disabled
            } else {
                if (this.permissionField == null) {
                    this.formValidationButton = true
                } else {
                    this.formValidationButton = false
                }
            }


        },
        submitForm() {
            if (this.isFormValid) {

                var data = {}
                data.email = this.emailField
                if (this.leaderSelectField) {
                    if (this.leaderSelectField.id) {
                        data.leaderId = this.leaderSelectField.id
                        if (this.leaderEarningsField) {
                            data.leaderEarnings = this.leaderEarningsField
                        } else {
                            data.leaderEarnings = 0
                        }
                    } else {
                        data.leaderId = null
                        data.leaderEarnings = 0
                    }
                } else {
                    data.leaderId = null
                    data.leaderEarnings = 0
                }
                if (this.ownerSelectField) {
                    if (this.ownerSelectField.id) {
                        data.ownerId = this.ownerSelectField.id
                        if (this.ownerEarningsField) {
                            data.ownerEarnings = this.ownerEarningsField
                        } else {
                            data.ownerEarnings = 0
                        }
                    } else {
                        data.ownerId = null
                        data.ownerEarnings = 0
                    }
                } else {
                    data.ownerId = null
                    data.ownerEarnings = 0
                }

                if (this.permissionField) {
                    data.domainPermissionsId = this.permissionField.id
                }


                data.orderTypes = this.selectedOrderTypes

                const json = JSON.stringify(data);
                this.$emit('data', json);
            }
        },
    },
    mounted() {
        if (this.type == "CREATE") {
            this.isTitleValid = true
            this.isDescriptionValid = true
            this.titleValidationMessage = ""
            this.descriptionValidationMessage = ""
            this.formValidationButton = true
        }
        this.getPermissions()
    }
}

</script>